import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

const swiper = new Swiper('#js-homeHero .swiper', {
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
  loop: true,
  pagination: {
    clickable: true,
    el: '#js-homeHero .swiper-pagination',
  },
  spaceBetween: 0,
});
