import $ from "jquery";

$('.js-localNavParent').on('click', (event) => {
  let screenWidth = $(window).width();
  if(screenWidth < 767){
    $(event.currentTarget).toggleClass('-active');
    $(event.currentTarget).next('.js-localNavChild').slideToggle();
  }
  return false;
});
