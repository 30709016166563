import $ from "jquery";

$('.js-section').on('click', (event) => {
  $(event.currentTarget).toggleClass('-active');
  $(event.currentTarget).next('.js-body').slideToggle();
	return false;
});

$('select').change(function () {
  var speed = 400;
  var href = $(this).val();
  var target = $(href == "#" || href == "" ? 'html' : href);
  var position = target.offset().top - 100;
  $('body, html').animate({scrollTop:position}, speed, 'swing');
  return false;
});
